<template>
	<div class="table">
		<div class="but">
			<el-button type="primary" icon="el-icon-circle-plus-outline" @click="newlyadded(2)" >新增裁判员证书</el-button>
		</div>
		<el-table :data="tableData" v-loading="loading" border style="width: 100%;" :header-cell-style="{ background: '#0A6DF3' }" :default-sort = "{prop: 'date', order: 'descending'}">
			<el-table-column type="index" label="序号" align="center" width="78" sortable>
			</el-table-column>
			<el-table-column prop="refereeName" label="姓名" align="center">
			</el-table-column>
			<el-table-column prop="refereeSex" label="性别" align="center">
				<template slot-scope="scope">
					{{ scope.row.refereeSex == 1 ? '男':(scope.row.refereeSex == 2 ? '女':'其他')}}
				</template>
			</el-table-column>
			<el-table-column prop="sportsName" label="项目" align="center">
			</el-table-column>
			<el-table-column prop="referee_Level" label="等级" align="center">
			</el-table-column>
			<el-table-column prop="certificate_area" label="注册地区" align="center">
			</el-table-column>
			<el-table-column prop="certificateUnit" label="发证单位" align="center">
			</el-table-column>
			<el-table-column prop="applyForStatus" label="注册状态" align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.applyForStatus == 10">草稿</div>
					<div v-if="scope.row.applyForStatus == 20">待审批</div>
					<div v-if="scope.row.applyForStatus == 30">
						<el-link type="primary" :underline="false" @click="getclick(scope.row)">审核未通过</el-link></div>
					<div v-if="scope.row.applyForStatus == 40">已公示</div>
					<div v-if="scope.row.applyForStatus == 60">已过期</div>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="操作" align="center">
				<template slot-scope="scope">
					<div v-if="$store.state.realnameornot.requeststatus == 1 || $store.state.realnameornot.caStatus == 1">
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" @click="newlyadded(3,scope.row)">查看</el-link>
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" v-if="scope.row.applyForStatus == 10" @click="newlyadded(5,scope.row)">编辑</el-link>
						
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" v-if="scope.row.applyForStatus == 30" @click="newlyadded(7,scope.row)">编辑</el-link>
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" v-if="scope.row.applyForStatus == 40" @click="newlyadded(4,scope.row)">申请修改</el-link>
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" v-if="scope.row.applyForStatus == 60" @click="newlyadded(6,scope.row)">年度注册</el-link>
					</div>
					<div v-else>
						<el-link type="primary" :underline="false" style="margin-right: 10PX;" @click="newlyadded(3,scope.row)">查看</el-link>
					</div>
				</template>
			</el-table-column>
		</el-table>
		
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10"></pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				tableData: [],
				info:{
					userId:null,
					pageNo:1,
					pageSize:10,
					refereeleve:'',
					certificateArea:'',
					sports:'',
					refereeName:'',
				},
				total:0,
				options2:[],
				options3:[],
				loading:false
			}
		},
		created() {
			this.judgmentLevel()
			this.areaList()
			this.getRefereeInfoList()
		},
		methods: {
			judgmentLevel() {
				this.$api.judgmentLevel().then(res => {
					this.options2 = res.data.data.result
				})
			},
			areaList() {
				this.$api.areaList().then(res => {
					this.options3 = res.data.data.result
				})
			},
			newlyadded(e,id){
				let data = {
					index:e,
					id:id
				}
				this.$emit("newlyadded",data) // 0 代表区分是当前页面还是 切换其他页面
			},
			currentchange(val) {
				this.info.pageNo = val
				this.getRefereeInfoList()
			},
			getRefereeInfoList(){
				this.loading = true
				this.info.userId = this.$store.state.userid || null
				this.$api.getRefereeInfoList(this.info).then(res=>{
					res.data.data.result.records.forEach(item=>{
						for(let i in this.options3){
							if(item.certificateArea == this.options3[i].itemValue){
								item.certificate_area = this.options3[i].itemText
							}
						}
						for(let i in  this.options2){
							if(item.refereeLevel == this.options2[i].itemValue){
								item.referee_Level = this.options2[i].itemText
							}
						}
					})
					res.data.data.result.records.forEach(item=>{
						item.refereeName = this.$decrypt(item.refereeName)
						item.certificateUnit = this.$decrypt(item.certificateUnit)
					})
					setTimeout(()=>{
						this.tableData = res.data.data.result.records
						this.total = res.data.data.result.total
						this.loading = false
					},1000)
				}).catch(error => {
					this.loading = false
				})
			},
			register(item){
				let info = {
					id:item.id,
					applyForStatus:60
				}
				this.$api.updateRefereeCertificate(info).then(res => {
					this.$message.success('操作成功')
					this.getRefereeInfoList()
				})
			},
			getclick(row){
				this.$alert(row.applyContent, '审批意见', {
				  confirmButtonText: '关闭',
				  callback: action => {}
				});
			}
		}
	}
</script>
<style>
	.el-message-box__wrapper .el-message-box{
		width: 420PX;
	}
</style>
<style lang="scss" scoped>
	.table {
		margin-top: 40PX;
		margin-bottom: 80PX;
		margin-left: 23PX;
		margin-right: 23PX;
	}
	
	.table ::v-deep .el-table thead {
		color: #fff;
		font-size: 16PX;
		font-weight: 500;
	}
	.table ::v-deep .el-table__header-wrapper{
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}
	.table ::v-deep .el-table{
		border-top-left-radius: 10PX;
		border-top-right-radius: 10PX;
	}
	.table ::v-deep .el-table th.el-table__cell.is-leaf {
	    border-bottom: 1PX solid #333333;
		border-right: 1PX solid #333333;
	}
	.table ::v-deep .el-table td, .el-table th.is-leaf,.el-table--border, .el-table--group{
	  border-color: #333333; 
	}
	.table ::v-deep .el-table--border::after, .el-table--group::after, .el-table::before{
	  background-color: #333333;
	}
	.el-link.el-link--primary{
		color: #0A6DF3;
	}
	.but{
		margin-left: 23PX;
		margin-right: 23PX;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 23PX;
	}
	
	.el-button--primary {
		width: 168PX;
		height: 39PX;
	    color: #FFF;
	    background-color: #348AFE;
	    border-color: #348AFE;
	}
	
	.paging {
		margin-top: 21PX;
		margin-bottom: 133PX;
		display: flex;
		align-items: center;
	}
	
	.paging span {
		margin-right: 5PX;
	}
</style>